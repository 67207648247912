<template>
  <v-container class="general">
    <page-title :component="'UsersPage'"></page-title>
    <v-layout row wrap>
      <v-flex xs12 mb-4>
        <v-stepper v-model="stepper" non-linear alt-labels>
          <v-stepper-header class="no-shadow">
            <v-stepper-step
              v-for="(header, headerIndex) in steps"
              :key="'step-header-' + headerIndex"
              :step="headerIndex + 1"
              :editable="header.editable"
              >{{ header.name }}</v-stepper-step
            >
          </v-stepper-header>
          <v-divider></v-divider>
          <v-stepper-items>
            <template v-for="(item, itemIndex) in steps">
              <v-stepper-content
                :step="itemIndex + 1"
                class="pa-0"
                :key="'step-item-' + itemIndex"
              >
                <v-card class="pa-4">
                  <div v-if="stepper == 1">
                    <v-layout row wrap text-xs-center v-show="!addedFile">
                      <v-flex xs12 mb-4 class="headline">
                        {{ $t("do-you-have-the-sample") }}
                      </v-flex>
                      <v-flex xs12 mb-4>
                        <vue-dropzone
                          ref="customDropzone"
                          id="dropzone-custom"
                          @vdropzone-sending="vdropzoneSending"
                          @vdropzone-success="vdropzoneSuccess"
                          @vdropzone-error="vdropzoneError"
                          @vdropzone-file-added="vdropzoneAddedFile"
                          :options="dropzoneOptions"
                          :useCustomSlot="true"
                        >
                          <div class="dropzone-custom-content">
                            <h3 class="dropzone-custom-title">
                              {{ $t("media_manager.drag_and_drop_to_upload") }}
                            </h3>
                            <div class="subtitle">
                              {{
                                $t(
                                  "media_manager.click_to_select_a_file_from_your_computer",
                                )
                              }}
                              {{
                                $t("allowed-file-types", {
                                  file_types: "*.xlsx",
                                })
                              }}
                            </div>
                          </div>
                        </vue-dropzone>
                      </v-flex>
                      <v-flex xs12 mb-4>
                        <v-layout row wrap class="align-items-center">
                          <v-flex>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-flex xs1 class="headline">{{ $t("or") }}</v-flex>
                          <v-flex>
                            <v-divider></v-divider>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-btn
                          round
                          class="sw-accent-bg sw-on-accent text-none"
                          @click="stepper = 2"
                          >{{ $t("create-sample") }}</v-btn
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap class="text-center" v-if="addedFile">
                      <v-flex xs12 class="headline">
                        {{
                          $t("group_user_list_page.import_uploaded_file_label")
                        }}
                      </v-flex>
                      <v-flex xs12 class="mb-4">
                        {{ addedFile }}
                      </v-flex>
                      <v-flex xs12 class="mb-4 headline">
                        {{ $t("import-confirmation-text") }}
                      </v-flex>
                      <v-flex xs12 class="mb-2">
                        {{ $t("group_user_list_page.import_description") }}
                      </v-flex>
                      <v-flex xs12>
                        <v-checkbox
                          v-model="allowResetAttributes"
                          :label="
                            $t('attribute_import.reset-relational-answer')
                          "
                          :disabled="isLoading"
                          class="display-flex justify-center"
                          hide-details
                        >
                        </v-checkbox>
                      </v-flex>
                      <v-flex xs12 class="mb-4">
                        <v-checkbox
                          v-model="isTermsAccepted"
                          :label="
                            $t('group_user_list_page.terms_accepted_at_label')
                          "
                          :disabled="isLoading"
                          class="display-flex justify-center"
                          hide-details
                        >
                        </v-checkbox>
                      </v-flex>
                      <v-flex xs12>
                        <v-btn
                          round
                          class="white sw-primary text-none"
                          @click="removeAllFiles"
                          :disabled="isLoading"
                          >{{ $t("common.cancel") }}</v-btn
                        >
                        <v-btn
                          round
                          class="sw-accent-bg sw-on-accent text-none"
                          @click="processQueue"
                          :loading="isLoading"
                        >
                          {{ $t("import-confirmation-button") }}
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-layout row wrap v-if="stepper == 2">
                    <v-flex xs12 mb-4 class="headline text-xs-center">
                      {{
                        $t(
                          "attribute_import.what-is-the-data-called-that-you-want-to-import",
                        )
                      }}
                    </v-flex>
                    <v-flex xs12>
                      <v-layout row wrap>
                        <v-flex xs12 mb-4>
                          <v-combobox
                            v-model="input"
                            :items="items"
                            item-text="name"
                            item-value="id"
                            return-object
                            :label="$t('questions')"
                            :disabled="exceededMaxAllowedParameters"
                            :menu-props="{ closeOnContentClick: true }"
                            @input="selectItem"
                            @keyup.enter="selectItem"
                            :search-input.sync="search"
                            no-filter
                          >
                            <template slot="item" slot-scope="data">
                              <v-layout class="align-items-center">
                                <v-flex xs6>{{ data.item.name }} </v-flex>
                                <v-flex xs6 text-xs-right class="grey--text">
                                  {{ data.item.tag }}
                                </v-flex>
                              </v-layout>
                            </template>
                            <template slot="no-data">
                              <div class="px-3 py-2 sw-caption grey--text">
                                <div v-if="isLoading">
                                  {{
                                    $t("group_user_list_page.import_searching")
                                  }}
                                </div>
                                <div v-if="!isLoading">
                                  {{
                                    $t(
                                      "group_user_list_page.import_nothing_found",
                                    )
                                  }}
                                </div>
                              </div>
                            </template>
                          </v-combobox>
                          <span
                            v-if="exceededMaxAllowedParameters"
                            class="accent-text"
                            >{{
                              $t("attribute_import.question-limit", {
                                limit: maxAllowedParameters,
                                type: "report",
                              })
                            }}</span
                          >
                        </v-flex>
                        <v-flex xs12 mb-4 v-if="params && params.length > 0">
                          <vue-draggable
                            class="vue-draggable"
                            v-model="params"
                            :handle="'.draggable-handle'"
                          >
                            <div
                              v-for="(selected, selectedIndex) in params"
                              :key="'selected-' + selectedIndex"
                            >
                              <v-hover>
                                <v-card slot-scope="{ hover }">
                                  <v-layout
                                    row
                                    no-wrap
                                    py-2
                                    px-3
                                    align-items-center
                                    :class="hover ? 'hover grey lighten-4' : ''"
                                  >
                                    <span
                                      v-if="params && params.length > 1"
                                      class="draggable-handle sw-accent mr-3"
                                    >
                                      <font-awesome-icon icon="grip-vertical" />
                                    </span>
                                    <v-flex class="v-ellipsis xs8">
                                      <v-tooltip top>
                                        <span
                                          slot="activator"
                                          icon
                                          flat
                                          class="ma-0"
                                        >
                                          {{ selected.name }}
                                        </span>
                                        <span>{{ selected.name }}</span>
                                      </v-tooltip>
                                    </v-flex>
                                    <v-flex
                                      xs4
                                      class="text-xs-right grey--text pr-3"
                                    >
                                      {{ selected.tag }}
                                    </v-flex>
                                    <v-btn
                                      class="ma-0"
                                      flat
                                      icon
                                      @click="removeAttribute(selected.id)"
                                      :disabled="
                                        selected.persistent || isLoading
                                      "
                                    >
                                      <font-awesome-icon
                                        icon="times"
                                        :class="{
                                          'sw-accent':
                                            !selected.persistent && !isLoading,
                                        }"
                                      />
                                    </v-btn>
                                  </v-layout>
                                </v-card>
                              </v-hover>
                            </div>
                          </vue-draggable>
                        </v-flex>
                        <v-flex xs12 text-xs-center>
                          <v-layout row wrap>
                            <v-flex xs12 mb-4 class="headline">
                              {{ $t("download-sample") }}
                            </v-flex>
                            <v-flex xs12>
                              <v-btn
                                round
                                class="sw-accent-bg sw-on-accent text-none"
                                @click="getSample"
                                :loading="isLoading"
                              >
                                {{ $t("download") }}
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-stepper-content>
            </template>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from "axios";
import fileDownload from "js-file-download";

export default {
  data: () => ({
    userAttributes: [],
    input: null,
    search: null,
    searchTimeout: null,
    params: [],
    stepper: null,
    dropzoneOptions: {},
    addedFile: "",
    isLoading: false,
    allowResetAttributes: false,
    isTermsAccepted: false,
  }),
  computed: {
    ...mapGetters(["getToken"]),
    token() {
      return this.getToken;
    },
    groupId() {
      return this.$route.params.group_id;
    },
    steps() {
      return [
        {
          id: 1,
          name: this.$t("import"),
          editable: true,
        },
        {
          id: 2,
          name: this.$t("create"),
          editable: true,
        },
      ];
    },
    maxAllowedParameters() {
      return (
        parseInt(process.env.VUE_APP_USER_IMPORT_SAMPLE_COLUMN_LIMIT + "") || 20
      );
    },
    exceededMaxAllowedParameters() {
      return this.params.length >= this.maxAllowedParameters;
    },
    items() {
      if (this.isLoading) return [];

      const selectedIds = this.params.map((el) => el.id);

      return this.userAttributes.filter((el) => !selectedIds.includes(el.id));
    },
    defaultParams() {
      return [
        {
          id: "id",
          name: this.$t("group_user_list_page.import_user_id_param_label"),
          tag: this.$t("group_user_list_page.import_default_param_tag_name"),
          persistent: true,
        },
        {
          id: "first_name",
          name: this.$t("group_user_list_page.import_first_name_param_label"),
          tag: this.$t("group_user_list_page.import_default_param_tag_name"),
          persistent: true,
        },
        {
          id: "last_name",
          name: this.$t("group_user_list_page.import_last_name_param_label"),
          tag: this.$t("group_user_list_page.import_default_param_tag_name"),
          persistent: true,
        },
        {
          id: "email",
          name: this.$t("group_user_list_page.import_email_param_label"),
          tag: this.$t("group_user_list_page.import_default_param_tag_name"),
          persistent: true,
        },
        {
          id: "phone_code,phone",
          name: this.$t("group_user_list_page.import_phone_param_label"),
          tag: this.$t("group_user_list_page.import_default_param_tag_name"),
          persistent: true,
        },
      ];
    },
  },
  components: {
    "vue-draggable": draggable,
    "vue-dropzone": vue2Dropzone,
  },
  mounted() {
    this.dropzoneInit();
    this.listGroupUserAttributes();
    this.params = [...this.defaultParams];
  },
  watch: {
    search: {
      immediate: true,
      handler() {
        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(
          () => this.listGroupUserAttributes(),
          500,
        );
      },
    },
    stepper: {
      handler() {
        this.removeAllFiles();
      },
    },
  },
  methods: {
    addItem(item) {
      if (!item || !item.id) return;

      this.params.push(item);

      this.$nextTick(() => (this.input = null));
    },
    selectItem(item) {
      if (this.isLoading) return;

      if (this.items && this.items.length === 1) {
        this.addItem(this.items[0]);
        return;
      }

      if (!item || !item.id) return;

      this.addItem(item);
    },
    removeAllFiles() {
      if (!this.$refs.customDropzone || !this.$refs.customDropzone[0]) {
        return;
      }

      this.$refs.customDropzone[0].removeAllFiles();
      this.addedFile = null;
    },
    processQueue() {
      if (!this.$refs.customDropzone) return;
      this.isLoading = true;
      this.$refs.customDropzone[0].processQueue();
    },
    dropzoneInit() {
      this.dropzoneOptions = {
        url: `${process.env.VUE_APP_API_URL}groups/${this.groupId}/users/attributes/import`,
        acceptedFiles: ".xlsx",
        maxFiles: 1,
        maxFilesize: 5,
        previewsContainer: false,
        autoProcessQueue: false,
      };
    },
    vdropzoneAddedFile(file) {
      this.addedFile = file.name;
    },
    vdropzoneSending: function (file, xhr, formData) {
      if (this.token) {
        formData.append(
          "allowResetAttributes",
          Boolean(this.allowResetAttributes),
        );

        formData.append(
          "imported_users_terms_accepted",
          this.isTermsAccepted ? 1 : 0,
        );

        xhr.setRequestHeader("Authorization", "Bearer " + this.token);
      }
    },
    vdropzoneError(file, message, xhr) {
      this.isLoading = false;

      if (message !== "Upload canceled.") {
        this.errorMessageShow(message);
      }

      this.removeAllFiles();
      this.addedFile = "";
    },
    vdropzoneSuccess(file, response) {
      this.isLoading = false;
      this.allowResetAttributes = false;
      this.removeAllFiles();
      this.addedFile = "";
      this.$store.dispatch("addNotification", {
        message: this.$t("group_user_list_page.import_success_message"),
      });
    },
    removeAttribute(id) {
      if (id) {
        let index = this.params.findIndex((attribute) => attribute.id == id);
        if (index > -1) {
          this.$delete(this.params, index);
        }
      }
    },
    isSelected(id) {
      if (id) {
        let index = this.params.findIndex((attribute) => attribute.id == id);
        if (index > -1) {
          return true;
        }
      }
    },
    transformUserAttributes(attributes) {
      return attributes.map((el) => ({
        id: el.id,
        name: el.name,
        tag: el.group_plugin ? el.group_plugin.name : "",
      }));
    },
    async listGroupUserAttributes() {
      try {
        const params = [
          this.groupId,
          {
            search: this.search,
            per_page: 50,
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupUserAttributes.list(...params);

        this.isLoading = false;

        this.userAttributes = this.transformUserAttributes(response.data.data);
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    getSample() {
      const query = this.params.map((attribute) => attribute.id).toString();

      this.isLoading = true;

      axios({
        url: `${process.env.VUE_APP_API_URL}groups/${this.groupId}/users/attributes/sample?attributes=${query}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          fileDownload(response.data, "sample.xlsx");
        })
        .catch((error) => {})
        .finally(() => {
          this.isLoading = false;
          this.params = [...this.defaultParams];
          this.stepper = 1;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.hover {
  cursor: pointer;
}

.vue-draggable .draggable-handle {
  cursor: grabbing;
}

.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: $accent;
}

.v-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme-danger {
  color: $accent !important;
}
</style>
